import { reactiveComputed } from '@vueuse/core'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { computed } from 'vue'
import { intersection } from 'lodash-es'

interface IShortcut {
  dur: number
  unit: 'h' | 'd'
  value: number
  label?: string | number
}

export function useRentalTime(mode: 'c2c' | 'b2c' | 'union' = 'b2c') {
  const { config } = useAppState()
  const timeRange = computed(() => {
    const arr = mode === 'b2c' ? config?.value?.order?.time_range_b2c : config?.value?.order?.time_range_c2c

    if (mode === 'union') {
      const [c2cTimeRange, b2cTimeRange] = [config?.value?.order?.time_range_c2c, config?.value?.order?.time_range_b2c]

      const union = intersection(toValue(c2cTimeRange), toValue(b2cTimeRange))

      return union
    }

    return arr ?? []
  })
  dayjs.extend(duration)

  const shortcutTime: IShortcut[] = reactiveComputed(() => {
    //  为实现数据未加载结束看不到对应的数据
    const defaultTime: IShortcut[] = [
      {
        dur: 0,
        unit: 'h',
        value: 0,
        label: '',
      },
    ]
    return timeRange.value.length === 0 ? defaultTime : timeRange.value.map(formatTime)
  })

  function formatTime(seconds: number): IShortcut {
    const duration = dayjs.duration(seconds, 'seconds')
    const hours = duration.hours() // 获取小时数
    const days = duration.days() // 获取天数
    const month = duration.months() // 获取天数

    let option: IShortcut = {
      value: seconds,
      dur: 0,
      unit: 'd',
      label: 0,

    }
    if (month) {
      option = {
        value: seconds,
        dur: 30,
        unit: 'd',
        label: 30,
      }
    }
    if (days) {
      option = {
        value: seconds,
        dur: days,
        unit: 'd',
        label: days,
      }
    }
    if (hours) {
      option = {
        value: seconds,
        dur: hours,
        unit: 'h',
        label: hours, // todo 不在需要label属性
      }
    }

    return option
  }

  return {
    shortcutTime,
    timeRange,
  }
}
