<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import { useRentalTime } from '~/composables/useRentalTime'

const props = withDefaults(defineProps<{
  mode: 'b2c' | 'c2c' | 'union'
  rentTimeSecond: number
}>(), {
  mode: 'b2c',
})

const emits = defineEmits(['onClick'])

const { shortcutTime } = useRentalTime(props.mode)
const showDurationSelect = useBoolean(false)

useEventListener(document, 'touchmove', () => {
  showDurationSelect.is = false
})

function onClick(value: number) {
  showDurationSelect.setFalse()
  emits('onClick', value)
}
</script>

<template>
  <div data-test-id="m4yk" @click="showDurationSelect.setTrue()">
    <APopover v-model:open="showDurationSelect.is" placement="topLeft" arrowPointAtCenter overlayClassName="z-popover">
      <template #content>
        <div class="popover-text ">
          <div class="mb-[11px]">
            <div class="text-primary mb2">{{ $t('rmt5Bx6fziWlgBfPjl0Wj') }}</div>
            <div class="item">
              <template v-for="(i, k) in shortcutTime" :key="i?.value">
                <div v-if="i.unit === 'h'" class="shortcu-btn" :class="{ active: Number(i.value) === props.rentTimeSecond }" :data-test-id="`f57f${k}`" @click="onClick(i?.value)">
                  {{ i?.label }}
                </div>
              </template>
            </div>
          </div>
          <div>
            <div class="text-primary mb2">{{ $t('ckgAv5ERgCLp4Pp0oG2eZ') }}</div>
            <div class="item">
              <template v-for="(i, k) in shortcutTime" :key="i?.value">
                <div v-if="i?.unit === 'd'" class="shortcu-btn" :class="{ active: Number(i.value) === props.rentTimeSecond }" :data-test-id="`o485${k}`" @click="onClick(i?.value)">{{ i?.label }}</div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <slot />
    </APopover>
  </div>
</template>

<style lang="scss">
.ant-popover.z-popover{
  color: #E2E8F2;
  border: none;

  .ant-popover-inner{
    background: #202C52;
  }

  .ant-popover-arrow::before{
    background: #202C52;
  }

  .popover-text{
    --uno: 'px-6px py-4px';

    .item{
      --uno: 'grid grid-cols-5 gap-4px';

      .shortcu-btn{
        --uno: 'text-text cursor-pointer hover:bg-#266EF1 w-2.3em flex-center rounded-sm h-2.3em';
      }

      .active{
        --uno: 'bg-#266EF1';
      }
    }
  }
}

.z-input-number{
  .ant-input-number-group-addon{
    background: #13192E;
  }
}
</style>
